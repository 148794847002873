import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'home',
    children: [
    {
     path : '',
     loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },

    {
     path : ':postId',
     loadChildren: () => import('./pages/post/post.module').then( m => m.PostPageModule)
    }
    ]
  },


  { path: 'posts',
  children: [
  {
  path : '',
  loadChildren: () => import('./pages/posts/posts.module').then( m => m.PostsPageModule)
  },

  {
  path : ':postId',
  loadChildren: () => import('./pages/post/post.module').then( m => m.PostPageModule)
  }
  ]
},

  { path: 'categories',
    children: [
    {
    path : '',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
    },

    {
    path : ':catId',
    loadChildren: () => import('./pages/categorypost/categorypost.module').then( m => m.CategorypostPageModule)
    }
    ]
  },

  { path: 'tags',
    children: [
    {
    path : '',
    loadChildren: () => import('./pages/tags/tags.module').then( m => m.TagsPageModule)
    },

    {
    path : ':tagId',
    loadChildren: () => import('./pages/taggedpost/taggedpost.module').then( m => m.TaggedpostPageModule)
    }
    ]
  },

  { path: 'myposts',
    children: [
    {
    path : '',
    loadChildren: () => import('./pages/myposts/myposts.module').then( m => m.MypostsPageModule),
    canActivate: [AuthGuard]
    },

    {
    path : ':editPostId',
    loadChildren: () => import('./pages/updatepost/updatepost.module').then( m => m.UpdatepostPageModule)
    }
    ]
  },
  
  {
    path: 'latestposts',
    loadChildren: () => import('./pages/latestposts/latestposts.module').then( m => m.LatestpostsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'createpost',
    loadChildren: () => import('./pages/createpost/createpost.module').then( m => m.CreatepostPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myprofile',
    loadChildren: () => import('./pages/myprofile/myprofile.module').then( m => m.MyprofilePageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
